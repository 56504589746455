import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import "twin.macro"

import Hero from "../components/hero"
import ContentGrid from "../components/contentGrid"
import SectionTitle from "../components/sectionTitle"
import ProfilePhoto from "../components/profilePhoto"
import TitutiPhoto from "../components/titutiPhoto"
import WorksList from "../components/worksList"


const IndexPage = () => (
  <Layout workPage={false}>
    <Seo title="Home" workPage={false} />
    <Hero/>
    
    <ContentGrid>
      <div className="content-back" id="profile"></div>
      <div className="content-n1 lg:flex lg:mt-20">
        <div tw="relative">
          <div tw="w-52 md:w-60 h-auto -mt-20 mx-auto lg:mt-0">
            <ProfilePhoto/>
          </div>
          <h2 tw="
            absolute
            top-0
            flex
            flex-col
            justify-center
            items-center
            w-full
            h-full
            text-gold 
            text-base 
            text-center 
            leading-none
          ">
            <span tw="font-en text-6xl md:text-8xl font-normal">Profile</span>
            <span tw="-mt-2">プロフィール</span>
          </h2>
        </div>
        <div tw="mt-10 md:mt-16 lg:mt-0 lg:ml-20">
          <h2 tw="text-center lg:text-left"><span tw="font-en text-4xl text-gold">Yumiko KINJO</span><span tw="block tracking-wider">金城有美子</span></h2>
          <p tw="mt-10">沖縄県立芸術大学で陶磁器を学び、その後国内、 アジア各国にて数々の展示やインスタレーションを行う。沖縄の自然を感じる力強く繊細な造形、強い太陽に照らされたような色彩は『金城有美子』を見事に体現しており、各方面より大きな評価を得ている。精力的に制作を続け、現在さまざまな場所で作品を見ることができる。</p>
          <dl tw="mt-10">
            <dt tw="pt-2 font-en text-xl tracking-widest font-normal border-t border-gold_l text-gold">1997</dt>
            <dd tw="mt-2 tracking-wider text-sm">沖縄県立芸術大学大学院陶磁器科修了</dd>
            <dt tw="pt-2 font-en text-xl tracking-widest font-normal mt-4 border-t border-gold_l text-gold">1997~</dt>
            <dd tw="mt-2 tracking-wider text-sm">個展／沖縄、京都、大阪、宮崎</dd>
            <dd tw="tracking-wider text-sm">グループ展・国内／東京、茨城、高知、名古屋</dd>
            <dt tw="pt-2 font-en text-xl tracking-widest font-normal mt-4 border-t border-gold_l text-gold">2011~</dt>
            <dd tw="mt-2 tracking-wider text-sm">グループ展・海外／釜山、ソウル、台北、台中、香港</dd>
          </dl>
          </div>
      </div>
      
        <WorksList/>
      <div className="title-contact" tw="mt-36" id="contact">
        <SectionTitle title="contact"/>
      </div>
      <div className="content-contact" tw="mb-20">
        <p tw="mt-10 md:mt-16">各種お問い合わせは「tituti OKINAWAN CRAFT」のお問い合わせフォームより承っております。下記リンクボタンよりtituti OKINAWAN CRAFTのお問い合わせフォームへ移動し、必要事項をご記入の上送信してください。確認後折り返しご連絡させて頂きます。</p>
        <div tw="h-60 overflow-hidden mt-8">
          <TitutiPhoto/>
        </div>
        <a target="_blank" 
          rel="noopener noreferrer" 
          href="https://tituti.net/contact/" 
          className="group"
          tw="
            flex 
            items-center 
            justify-center 
            border 
            border-solid 
            border-black 
            font-en 
            text-xl 
            mt-10 
            p-4 
            tracking-widest
            hover:bg-gold
            hover:border-gold
            hover:text-white
            transition-colors
          " 
          title="tituti contact form">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0, 0, 28, 21" width="28" height="21" tw="transition-colors h-3 fill-current group-hover:text-white">
            <defs>
              <path fill="none" d="M0 0h28v21H0z"/>
            </defs>
            <g>
              <path d="M26.756 6.869c.098-.076.23-.09.342-.036s.183.167.183.291V18.2a2.6 2.6 0 0 1-2.6 2.6H2.147a2.6 2.6 0 0 1-2.6-2.6V7.129c0-.124.071-.237.183-.291s.244-.04.342.036c1.213.943 2.822 2.14 8.347 6.154 1.143.834 3.071 2.589 4.994 2.578s3.9-1.777 5-2.578l8.343-6.159m-13.342 7c1.257.022 3.066-1.582 3.976-2.243l9.393-6.972a1.3 1.3 0 0 0 .5-1.024V2.6a2.6 2.6 0 0 0-2.6-2.6H2.147a2.6 2.6 0 0 0-2.6 2.6v1.029a1.3 1.3 0 0 0 .5 1.024c1.658 1.3 2.2 1.755 9.393 6.972.91.661 2.719 2.264 3.976 2.243"/>
            </g>
          </svg>
          <span tw="ml-1">tituti contact form</span>
        </a>
      </div>
    </ContentGrid>
  </Layout>
)

export default IndexPage
