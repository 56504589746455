import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import "twin.macro"

const TitutiPhoto = () => {
  const data = useStaticQuery(graphql`
    query {
      tituti_photo: file(relativePath: { eq: "ph_tituti.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      },
    }
  `)
  return(
    <Img
      fluid={data.tituti_photo.childImageSharp.fluid}
      alt="tituti OKINAWAN CRAFT"
      tw="h-full w-full"
    />
  )
}

export default TitutiPhoto