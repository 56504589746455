import React from 'react'
import "twin.macro"
import { useStaticQuery, graphql } from "gatsby"
import WorkCard from "../components/workCard"
import SectionTitle from "../components/sectionTitle"
import Img from "gatsby-image"

const WorksList = () => {
  const data = useStaticQuery(graphql`
    {
      allMicrocmsArticles(
        sort: { fields: [workday], order: DESC }
      ) {
        edges {
          node {
            id
            title
            subtitle
            workday(formatString: "YYYY")
            featured_image {
              url
            }
          }
        }
      },
      w_re_01: file(relativePath: { eq: "ph_works_re-01.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      },
      w_re_02: file(relativePath: { eq: "ph_works_re-02.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      },
      w_re_03: file(relativePath: { eq: "ph_works_re-03.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      },
      w_re_04: file(relativePath: { eq: "ph_works_re-04.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      },
    }
  `)
  return(
    <>
      {
        data.allMicrocmsArticles.edges.length !== 0 ? (
          <>
          <div className="title-works" tw="mt-36" id="works">
            <SectionTitle title="works"/>
          </div>
          <div className="content-works" 
            tw="
              grid
              grid-cols-4
              sm:grid-cols-2
              lg:grid-cols-3
              md:mx-auto
              mt-10
              sm:mb-10 
              md:mb-16">
            {data.allMicrocmsArticles.edges.map((edge, index) => {
              const articles = edge.node
              
              return (
                <React.Fragment key={articles.id}>
                  <WorkCard 
                    id = {articles.id}
                    title = {articles.title} 
                    subtitle = {articles.subtitle}
                    workday = {articles.workday}
                    featuredImg = {articles.featured_image.url}
                    index = {index}
                  />
                </React.Fragment>
              )
            })}
          </div>
          </>
        ) : (
          <>
            <div className="works_replace" tw="mt-36 grid md:grid-cols-3">
              <div div tw="h-80 overflow-hidden">
                <Img
                  fluid={data.w_re_01.childImageSharp.fluid}
                  alt="work"
                  tw="h-full w-full"
                />
              </div>
              <div div tw="h-80 overflow-hidden">
                <Img
                  fluid={data.w_re_02.childImageSharp.fluid}
                  alt="work"
                  tw="h-full w-full"
                />
              </div>
              <div div tw="h-80 overflow-hidden">
                <Img
                  fluid={data.w_re_03.childImageSharp.fluid}
                  alt="work"
                  tw="h-full w-full"
                />
              </div>
            </div>
          </>
        )
      }
    </>
  )
}


export default WorksList