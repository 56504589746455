import React, { useEffect, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import "twin.macro"
import NewsList from "../components/newsList"
import ArchiveButton from "./archiveButton"

const HeroWrap = ({ children }) => (
  <div
    tw="
      w-full 
      h-screen
      relative
      overflow-hidden
      mb-40
      lg:mb-20
    "
  >
    { children }
    <div tw="absolute left-0 bottom-0 w-full px-8 py-8 z-30">
      <div tw="w-full sm:w-80">
        <div>
          <h3 tw="text-white">News / Events</h3>
        </div>
        <NewsList/>
        <ArchiveButton/>
      </div>
    </div>
  </div>
)

const Hero = () => {

  const [offset, setOffset] = useState(0)
  useEffect(() => {
    function handleScroll() {
      setOffset(window.pageYOffset)
    }
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  const data = useStaticQuery(graphql`
    query {
      hero_lg: file(relativePath: { eq: "ph_main_img.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      },
      hero_sm: file(relativePath: { eq: "ph_main_img-2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      },
      sign_svg: file(relativePath: { eq: "name.svg" }) {
        publicURL
      },
    }
  `)
  const { publicURL } = data.sign_svg;
  return (
    <HeroWrap>
      <Img
        fluid={data.hero_lg.childImageSharp.fluid}
        alt=""
        tw="w-full h-screen hidden md:block"
        style={{
          transform: `translateY(${offset * 0.2}px)`,
        }}
      />
      <Img
        fluid={data.hero_sm.childImageSharp.fluid}
        alt=""
        tw="w-full h-screen md:hidden"
        style={{
          transform: `translateY(${offset * 0.1}px)`,
        }}
      />
      <div
        tw="
          absolute
          top-0
          left-0
          w-full 
          h-full
          bg-black
          bg-opacity-30
        "
      >
      </div>
      <div
        tw="
          absolute
          top-0
          left-0
          flex
          flex-col
          justify-center
          items-center
          w-full 
          h-full
        "
      >
        <img src={publicURL} width="200" alt="Yumiko Kinjo"/>
        <span tw="font-en text-gold text-lg tracking-widest">YUMIKO  KINJO</span>
      </div>
    </HeroWrap>
  )
}

export default Hero
