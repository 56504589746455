import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import "twin.macro"

const ProfilePhoto = () => {
  const data = useStaticQuery(graphql`
    query {
      profile_photo: file(relativePath: { eq: "ph_profile.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      },
    }
  `)
  return(
    <Img
      fluid={data.profile_photo.childImageSharp.fluid}
      alt="yumiko kinjo"
    />
  )
}

export default ProfilePhoto