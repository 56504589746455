import React from 'react'
import { Link } from "gatsby"
import Imgix from "react-imgix"
import "twin.macro"

const NewsCard = ({ id, title, featuredImg, index }) => {
  return(
    <Link 
      to={`/news/${id}/`}
      title={ title }
      tw="block relative w-full h-full rounded-md overflow-hidden h-20 sm:h-32"
    >
      <div tw="w-full h-full absolute">
        <Imgix
          src={featuredImg}
          sizes="(max-width: 640px) 100vw, 640px"
          htmlAttributes={{
            alt: "",
          }}
          tw="w-full h-full object-cover transition-all"
        />
      </div>
      <h2 tw="w-full h-full flex justify-center items-center text-white text-sm tracking-wider absolute p-2">{title}</h2>
    </Link>
  )
}

export default NewsCard