import React from 'react'
import { Link } from "gatsby"
import Imgix from "react-imgix"
import "twin.macro"

const WorkCard = ({ id, title, subtitle, featuredImg, workday, index }) => {
  const workClass = index % 2 === 0 ? 'group works-grid-1-3' : 'group works-grid-2-4'
  
  return(
    <Link 
      to={`/works/${id}/`}
      title={ title }
      className={workClass}
      tw="block"
    >
      <div tw="w-full overflow-hidden" className="works_photo">
        <Imgix
          src={featuredImg}
          sizes="(max-width: 800px) 100vw, 800px"
          htmlAttributes={{
            alt: "",
          }}
          tw="w-full h-full object-cover transition-all transform group-hover:scale-105 ease-in"
        />
      </div>
      <div tw="font-en text-2xl text-gold tracking-wider">{workday}</div>
      <h2 tw="text-sm tracking-wider">{title}</h2>
      <h3 tw="text-sm font-normal opacity-50 tracking-wider">{subtitle}</h3>
    </Link>
  )
}

export default WorkCard