import React from 'react'
import "twin.macro"
import { useStaticQuery, graphql } from "gatsby"
import NewsCard from "../components/newsCard"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const NewsList = () => {
  const data = useStaticQuery(graphql`
    {
      allMicrocmsNews(
        filter: {on_display: {eq: true}},
        sort: {order: DESC, fields: publishedAt}
      ) {
        edges {
          node {
            id
            newsId
            title
            main_img {
              url
            }
            on_display
          }
        }
      },
    }
  `)
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 7000,
  };
  return(
    <>
    {
      data.allMicrocmsNews.edges.length !== 0 ? (
        <div>
          <div tw="h-20 sm:h-32">
          <Slider {...settings}>
          {data.allMicrocmsNews.edges.map((edge, index) => {
              const articles = edge.node
              return (
                <>
                {
                  articles.on_display && (
                    <React.Fragment key={articles.id}>
                      <NewsCard 
                        id = {articles.newsId}
                        title = {articles.title} 
                        featuredImg = {articles.main_img.url}
                        index = {index}
                      />
                    </React.Fragment>
                  )
                }
                </>
              )
            })}
          </Slider>
          </div>
        </div>
      ) :(
        <></>
      )
    }
    </>
  )
}


export default NewsList