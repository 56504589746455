import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import "twin.macro"
import { Parallax } from 'react-scroll-parallax';

const SectionTitle = ({ title }) => {
  const data = useStaticQuery(graphql`
    query {
      title_works: file(relativePath: { eq: "ph_ttlimg_works.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      },
      title_contact: file(relativePath: { eq: "ph_ttlimg_contact.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      },
    }
  `)
  return(
    <div tw="relative">
      <div className="title_box">
      <Parallax y={[-20, 20]} tagOuter="figure">
        
        {
          title === 'works' ?
          <Img
            fluid={data.title_works.childImageSharp.fluid}
            alt="works"
            tw="h-screen sm:h-full w-full"
          /> : 
          <Img
            fluid={data.title_contact.childImageSharp.fluid}
            alt="contact"
            tw="h-screen sm:h-full w-full"
          />
        }
      </Parallax>
      </div>
      <h2 className="secion_title-h2" 
        tw="
          absolute
          sm:relative
          sm:-mt-4
          md:-mt-6
          sm:z-10
          top-0
          left-0
          w-full
          h-full
          flex
          flex-col
          justify-center
          text-gold 
          text-base 
          text-center 
          leading-none
      ">
      {
        title === 'works' ?
          <><span tw="font-en text-6xl md:text-8xl font-normal">Works</span><span tw="-mt-2">作品</span></> :
          <><span tw="font-en text-6xl md:text-8xl font-normal">Contact</span><span tw="-mt-2">お問い合わせ</span></> 
      }
      </h2>
    </div>
  )
}

export default SectionTitle